import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalComponent } from '../../modals/modal.component';

@Component({
  selector: 'unlock-feature',
  templateUrl: './unlock-feature.component.html',
  styleUrls: ['./unlock-feature.component.scss']
})
export class UnlockFeatureComponent extends ModalComponent {

  constructor(
    public modalRef: BsModalRef
  ) {
    super(modalRef);
  }

  close() {
    super.reject();
  }

  confirm() {
    super.resolve();
  }

}
