import { Component } from '@angular/core';

import { UserauthApiService } from 'src/app/api/services/userauth-api.service';

@Component({
  selector: 'request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss']
})
export class RequestPasswordResetComponent {

  credentials: any = {};
  errors = {};
  emailSent = false;
  isGoogleAccount = false;
  isSsoAccount = false;
  termsNotAccepted;
  loading;

  constructor(private userauth: UserauthApiService) {}

  requestPasswordReset() {
    this.emailSent = false;
    this.isGoogleAccount = false;
    this.loading = true;

    this.userauth.canProceedWithSSO(this.credentials.username)
      .then((result) => {
        if (result.result.item) {
          this.isSsoAccount = true;
        } else {
          this.userauth.requestPasswordReset(this.credentials.username)
          .then(() => {
            console.log('Reset password request sent');
            this.emailSent = true;
          })
          .catch((err) => {
            if (err.status === 400) {
              console.log('Requested password reset for Google account');
              this.isGoogleAccount = true;
            } else if (err.status === 403) {
              this.termsNotAccepted = true;
            } else { // No special case for 404, for security reasons
              console.error(err);
              this.emailSent = true;
            }
          });
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

}