import { Component, Input, Output, EventEmitter } from '@angular/core';
import { StorageUtilsService } from '../../services/storage-utils.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'storage-list-view',
  templateUrl: './storage-list-view.component.html',
  styleUrls: ['./storage-list-view.component.scss']
})
export class StorageListViewComponent {

  @Input() files: any[];
  @Input() folderPath: string = '';
  @Input() filesDetails;
  @Input() fileListVisible;
  @Input() search;
  @Input() selectAll: boolean = false;
  @Output() selectAllCheckboxes: EventEmitter<void> = new EventEmitter<void>();
  @Output() fileClick: EventEmitter<any> = new EventEmitter<any>();

  constructor (
    public storageUtilsService: StorageUtilsService,
    public storageService: StorageService
  ) { }

  triggerFileClick (file) {
    this.fileClick.emit(file);
  }

  changeSort (field: string) {
    if (this.search.sortBy === field) {
      this.search.reverse = !this.search.reverse;
    } else {
      this.search.sortBy = field;
    }
  }

}