import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, NgZone } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';

import * as _ from 'lodash';

import { COUNTRIES } from 'src/app/ajs-upgraded-providers';

import { GeodataService } from 'src/app/shared/services/geodata.service';
import { CompanyIcpService } from '../../services/company-icp.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'company-fields',
  templateUrl: './company-fields.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  styleUrls: ['./company-fields.component.scss']
})
export class CompanyFieldsComponent implements OnInit, OnChanges {

  @Input() company: any = {};
  @Input() showManagedSections: boolean = false;
  @Output() resetAuthKeyEvent = new EventEmitter<string>();
  @Output() resetClaimIdEvent = new EventEmitter<string>();

  disableIndustry: boolean;
  isRiseStoreAdmin = this.userStateService.isRiseStoreAdmin();
  fuseApiKeyEditable: boolean = false;
  jamfSecretEditable: boolean = false;
  mosyleAccessTokenEditable: boolean = false;

  public companyForm: NgForm;

  regionsCA = GeodataService.REGIONS_CA;
  regionsUS = GeodataService.REGIONS_US;
  COMPANY_INDUSTRY_FIELDS = this.sort(CompanyIcpService.COMPANY_INDUSTRY_FIELDS);
  COMPANY_SIZE_FIELDS = CompanyIcpService.COMPANY_SIZE_FIELDS;
  JAMF_URL = 'https://help.risevision.com/hc/en-us/articles/24606283279252-How-do-I-enable-automatic-alerts-or-classroom-alerts-on-an-AppleTV';

  WEBSITE_REGEX = ValidationService.WEBSITE_REGEX;
  EMAIL_REGEX = ValidationService.EMAIL_REGEX;

  constructor(
    private controlContainer: ControlContainer,
    private ngZone: NgZone,
    public countries: COUNTRIES,
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    public currentPlanService: CurrentPlanService
  ) { }

  ngOnInit() {
    if (!this.company.settings) {
      this.company.settings = {};
    }

    this.companyForm = <NgForm>this.controlContainer;
  }

  updateEditableFields() {
    if (this.company && this.company.settings) {
      if (this.company.settings.fuseEnabled) {
        this.fuseApiKeyEditable = !this.company.settings.fuseApiKey;
      }

      if (this.company.settings.jamfEnabled && !this.company.settings.mosyleEnabled) {
        this.jamfSecretEditable = !this.company.settings.jamfClientSecret;
      }

      if (this.company.settings.mosyleEnabled && !this.company.settings.jamfEnabled) {
        this.mosyleAccessTokenEditable = !this.company.settings.mosyleAccessToken;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.company.settings && typeof this.company.settings.fuseEnabled === "string") {
      this.company.settings.fuseEnabled = this.company.settings.fuseEnabled === "true";
    }

    if (this.company.settings && typeof this.company.settings.jamfEnabled === "string") {
      this.company.settings.jamfEnabled = this.company.settings.jamfEnabled === "true";
    }

    if (this.company.settings && typeof this.company.settings.mosyleEnabled === "string") {
      this.company.settings.mosyleEnabled = this.company.settings.mosyleEnabled === "true";
    }

    this.updateEditableFields();

    if (changes.company) {
      this.disableIndustry = !!changes.company.currentValue.companyIndustry && !this.userStateService.isRiseAdmin();
    }

    this.detectChanges();
  }

  sort(items) {
    return _.sortBy(items, item => {
      return item[0];
    });
  }

  resetAuthKey() {
    this.resetAuthKeyEvent.emit();
  }

  resetClaimId() {
    this.resetClaimIdEvent.emit();
  }

  editFuseApiKey() {
    this.fuseApiKeyEditable = true;
  }

  editJamfSecret() {
    this.jamfSecretEditable = true;
  }

  editMosyleAccessToken() {
    this.mosyleAccessTokenEditable = true;
  }

  disableShareCompanyPlan() {
    return this.companyStateService.isRootCompanySelected() ||
      this.currentPlanService.isUnlimitedPlan() && !this.currentPlanService.isOnTrial() &&
      !this.company.shareCompanyPlan;
  }

  detectChanges() {
    setTimeout(() => {
      this.ngZone.run(() => { });
    });
  }

  redirect() {
    window.open(this.JAMF_URL, '_blank');
  }
}
