import { Directive, HostListener } from '@angular/core';

import { UserAuthFactory } from 'src/app/ajs-upgraded-providers';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { ModalService } from 'src/app/components/modals/modal.service';

@Directive({
  selector: '[sign-out-button]'
})
export class SignOutButtonDirective {

  constructor(private userStateService: UserStateService,
    private userAuthFactory: UserAuthFactory,
    private modalService: ModalService) { }

  _signOut() {
    this.userAuthFactory.signOut()
      .then(() => {
        console.debug('User signed out.');
      })
      .catch((err) => {
        console.debug('User sign out failed', err);
      });
  }

  logout() {
    if (this.userStateService.isRiseAuthUser()) {
      this._signOut();
    } else {
      return this.modalService.confirm('Sign Out of Rise Vision',
        'Signing out does not sign you out of your Google Account. If you are on a shared computer you should sign out of your Google Account.',
        'Sign Out',
        'Cancel')
        .then(() => {
          this._signOut();
        })
        .catch(() => {});
    }
  }

  @HostListener('click') onClick() {
    this.logout();
  }

}