import { Component, Input } from '@angular/core';

import * as angular from 'angular';

@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {

  @Input() isMultipleFilesFoldersSelector: boolean;

}