import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

/**
 * Displays an alert notification with optional title and message
 * @example
 * <notification-alert
 *   type="warning"
 *   title="Certificate Expired"
 *   message="Your tax exemption certificate has expired."
 * ></notification-alert>
*/
export type AlertType = 'info' | 'success' | 'warning' | 'danger';

@Component({
  selector: 'notification-alert',
  standalone: true,
  imports: [NgClass],
  templateUrl: './notification-alert.component.html',
  styleUrl: './notification-alert.component.scss'
})
export class NotificationAlertComponent {
  @Input() message: string;
  @Input() type: AlertType = 'info';
  @Input() title: string;
  @Input() cssClass: string = '';
  @Input() id: string;
  @Input() boldTitle: boolean = true;

  protected iconClass = {
    info: 'fa-info-circle rise-blue',
    success: 'fa-exclamation-circle rise-green',
    warning: 'fa-exclamation-circle text-warning',
    danger: 'fa-times-circle text-danger'
  } as const;
}
