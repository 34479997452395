<div class="component-header aligner" [ngClass]="{ 'border-bottom': componentsService.isHeaderBottomRuleVisible(componentsService.selected) }">
  <a tabindex="0" id="back-button" class="madero-link" (click)="componentsService.onBackButton();">
    <i aria-label="Go back" class="fa fa-lg fa-angle-left arrow-icon pr-3"></i>Back
  </a>
  <streamline-icon aria-hidden="true" class="streamline-component-icon" name="{{ componentsService.getComponentIcon(componentsService.selected) }}" width="24" height="18"></streamline-icon>
  <span class="u_text-ellipsis mr-auto">{{ getComponentLabel(componentsService.selected) }}</span>
  @if (componentsService.getCurrentPage() && componentsService.getCurrentPage().type === 'rise-storage-selector' && storageManagerService.fileType === 'image') {
    <list-grid-toggle [(isListView)]="storageManagerService.isListView"></list-grid-toggle>
  }
</div>

<!-- Load instagram svg so that svg defs with ID(s) can be used by other instagram svg instances below. -->
<mat-icon aria-hidden="true" svgIcon="instagram" class="instagram-svg-defs"></mat-icon>

<div class="component-container">
  <component-media-selector></component-media-selector>
  <component-storage-selector></component-storage-selector>
  <component-playlist-item-selector></component-playlist-item-selector>
  <component-canva></component-canva>
  <template-component-branding></template-component-branding>
  <template-branding-colors></template-branding-colors>
  <template-component-schedules></template-component-schedules>
  <template-component-financial></template-component-financial>
  <template-component-folder></template-component-folder>
  <template-component-image></template-component-image>
  <template-component-slides></template-component-slides>
  <template-component-youtube></template-component-youtube>
  <template-component-text></template-component-text>
  <template-component-video></template-component-video>
  <template-component-rss></template-component-rss>
  <template-component-counter></template-component-counter>
  <template-component-time-date></template-component-time-date>
  <template-component-colors></template-component-colors>
  <template-component-twitter></template-component-twitter>
  <template-component-weather></template-component-weather>
  <component-presentation-selector></component-presentation-selector>
  <template-component-playlist></template-component-playlist>
  <template-component-html></template-component-html>
  <template-component-stock-library></template-component-stock-library>
  <template-component-calendar></template-component-calendar>
  <template-component-data-table></template-component-data-table>
  <template-component-web-page></template-component-web-page>
  <template-component-powerbi></template-component-powerbi>
  <template-component-document></template-component-document>
  <template-share-settings></template-share-settings>
  <template-component-social-media-posts></template-component-social-media-posts>
  <template-component-social-media-followers></template-component-social-media-followers>
</div>
