<div class="document-component-container">
  <div class="attribute-editor-component" rvSpinner="document-editor-loader" [rvShowSpinner]="spinner">
    <div class="document-component-settings te-scrollable-container">
      @if(revokeFailed) {
        <div class="attribute-editor-row">
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group">
                <div class="text-sm">
                  We could not revoke your access with Microsoft at this time. We strongly recommend you manually revoke access in your Microsoft account.
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      @if(authenticateFailed) {
        <div class="attribute-editor-row">
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group has-error">
                <p class="help-block">
                  <span>Sorry, there's an issue with authentication or the authentication window was blocked by the browser. Please try clicking the "Select from Microsoft OneDrive" button again.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      }

      <basic-uploader
          [uploaderId]="fileUploaderId + '-uploader'"
          [uploadManager]="storageUploadManager"
          [validType]="fileType"
          [shown]="isUploading">
        </basic-uploader>

      <!-- Select from UI-->
      @if(!source && !isUploading) {
        <div class="attribute-editor-row">
          <div class="row">
            <div class="col-xs-12">
              <div class="document-source-container form-group">
                <label>Choose a Powerpoint Source</label>
                <div class="source-button" (click)="selectFromOneDrive()">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/2/25/Microsoft_icon.svg" alt="Microsoft">
                  <span>Select from Microsoft OneDrive</span>
                  <i aria-hidden="true" class="fa fa-chevron-right"></i>
                </div>
                <div class="source-button" (click)="selectFromStorage()">
                  <streamline-icon aria-hidden="true" name="folderOpen" width="24" height="24"></streamline-icon>
                  <span>Select from Storage</span>
                  <i aria-hidden="true" class="fa fa-chevron-right"></i>
                </div>
                <button id="upload-file-unsubscribed" class="btn btn-default btn-block" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
                  Upload from Computer
                </button>
                <div class="source-button" [shown]="currentPlanService.isPlanActive()" (click)="uploadFromComputer()">
                  <streamline-icon aria-hidden="true" name="upload" width="24" height="24"></streamline-icon>
                  <span>Upload from Computer</span>
                  <i aria-hidden="true" class="fa fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      <!-- Connected Account & File Options UI-->
      @if(source && !isUploading) {
        <div class="attribute-editor-row">
          <div class="row">
            <div class="col-xs-12">
              @if(provider === 'ms-graph') {
                <label>Connected Account:</label>
              }
              @if(provider === 'rise-storage') {
                <label>My Powerpoint File</label>
              }
              <div class="provider-connected-container form-group">
                @if(provider === 'ms-graph') {
                  <div class="account-info">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/2/25/Microsoft_icon.svg" alt="Microsoft" class="account-logo">
                    <span class="account-email">{{componentAccountUsername}}</span>
                  </div>
                }
                <div class="provider-connected-file-info">
                  <div class="file-details">
                    <streamline-icon aria-hidden="true" name="powerpoint" width="20" height="20" class="file-icon"></streamline-icon>
                    <div class="file-text">
                      <span class="file-title">{{selectedName}}</span>
                      <span class="file-item-count">{{values.assetCount}} slides</span>
                    </div>
                  </div>
                  <button class="btn btn-icon pr-0" mat-icon-button [matMenuTriggerFor]="fileOptions" (menuOpened)="onDropdownOpen()">
                    <i class="fa fa-chevron-down file-options-icon" [ngClass]="{'clicked': isDropdownOpen}"></i>
                  </button>
                  <mat-menu #fileOptions="matMenu" xPosition="before" (closed)="onDropdownClose()">
                    <button mat-menu-item (click)="changeFile()">
                      <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="fileInverted" width="16" height="16"></streamline-icon>
                      <span>Change File</span>
                    </button>
                    @if(provider === 'ms-graph') {
                      <button mat-menu-item (click)="openInPowerPoint()">
                        <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="arrowDiagonalRight" width="16" height="16"></streamline-icon>
                        <span>Open in PowerPoint</span>
                      </button>
                      @if(sameAccount) {
                        <hr class="m-2">
                        <button mat-menu-item (click)="disconnect()">
                          <streamline-icon aria-hidden="true" class="mr-3 streamline-component-icon" name="unlink" width="16" height="16"></streamline-icon>
                          <span>Disconnect from Microsoft</span>
                        </button>
                      }
                    }
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
        </div>

        @if(fileAccessFailed || values.fileMissing || conversionError) {
          <div class="attribute-editor-row">
            <div class="row">
              <div class="col-xs-12">
                <div class="form-group">
                  <div class="provider-file-error has-error">
                    <streamline-icon aria-hidden="true" class="error-icon mr-3" name="xmark" width="24" height="24"></streamline-icon>
                    <div>
                      @if(fileAccessFailed || values.fileMissing) {
                        <p class="help-block mt-1 font-weight-bold">Uh oh! We can't find your file.</p>
                        <p>It looks like it might have been moved or deleted. Select another file to update your presentation.</p>
                      }
                      @else if (conversionError) {
                        <p class="help-block mt-1 font-weight-bold">Uh oh! The file could not be converted for use in the presentation.</p>
                      }
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        }

        <div class="attribute-editor-row">
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group">
                <label>Show each page for:</label>
                <div class="input-group">
                  <input type="number" id="document-component-duration" class="form-control" [(ngModel)]="values.duration" (change)="saveDuration()">
                  <span class="input-group-addon">Seconds</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="attribute-editor-row">
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group">
                <label class="control-label u_margin-sm-top">Transitions:</label>
                <select id="document-component-transition" class="form-control" [(ngModel)]="values.transition" (change)="saveTransition()">
                  <option value="">No Transition</option>
                  <option value="fadeIn">Fade In</option>
                  <option value="slideFromLeft">Slide from left</option>
                  <option value="slideFromRight">Slide from right</option>
                  <option value="slideFromTop">Slide from top</option>
                  <option value="slideFromBottom">Slide from bottom</option>
                  <option value="zoomIn">Zoom in</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        @if(provider === 'ms-graph') {
          <div class="attribute-editor-row">
            <div class="row">
              <div class="col-xs-12">
                <div class="form-group">
                  <label class="control-label u_margin-sm-top">Refresh Interval:</label>
                  <select id="document-component-refresh" class="form-control" [(ngModel)]="values.refresh" (change)="saveRefresh()">
                    <option value="60">1 Hour</option>
                    <option value="180">3 Hours</option>
                    <option value="360">6 Hours</option>
                    <option value="720">12 Hours</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        }
      }
    </div>
  </div>
</div>
