import { Component, Input } from '@angular/core';

import { AddressService } from 'src/app/shared/services/address.service';

@Component({
  selector: 'suggest-general-delivery',
  templateUrl: './suggest-general-delivery.component.html',
  styleUrls: ['./suggest-general-delivery.component.scss']
})
export class SuggestGeneralDeliveryComponent {
  @Input() addressObject: any;

  constructor(
    public addressFactory: AddressService
  ) { }

}