import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable } from '@angular/core';
import { LocationHelperService, WindowLocationService } from 'src/app/shared/services/location-helper.service';
import { LocalStorageHelperService } from 'src/app/shared/services/local-storage-helper.service';

@Injectable({
  providedIn: 'root'
})
export class RvshareAppDetectionService {
  private RVSHARE_APP_KEY = 'rvshare-app';

  constructor(
    private locationHelperService: LocationHelperService,
    private windowLocationService: WindowLocationService,
    private localStorageHelper: LocalStorageHelperService,
  ) { }
  
  resetScreenShareApp(): boolean {
    const location = this.windowLocationService.get().toString();
    const paramVal = this.locationHelperService.getSearchParam("rvshare");
    const containsRvshare = /[/?&]rvshare(?:[=&]|$)/.test(location) || (paramVal != null && paramVal !== "false");
    try {
      this.localStorageHelper.set(this.RVSHARE_APP_KEY, containsRvshare);
    } catch (error) {
      console.error(`Failed to update rvshare app state using key ${this.RVSHARE_APP_KEY}:`, error);
      return false;
    }
    return containsRvshare;
  }
  
  isScreenShareApp(): boolean {
    try {
      const value = this.localStorageHelper.get(this.RVSHARE_APP_KEY);
      return value === 'true' || value === true;
    } catch (error) {
      console.log(`Failed to get ${this.RVSHARE_APP_KEY} from local storage:`, error);
      return false;
    }
  }

  getInvalidCode(): string {
    return this.locationHelperService.getHashParam("invalidCode");
  }

  getConnectionError(): string {
    return this.locationHelperService.getHashParam("connectionError");
  }

  resetConnectionError(): void {
    if (!this.isScreenShareApp()) {return;}

    this.locationHelperService.removeHashParam("connectionError");
  }

  issueDeepLink(target: string): void {
    setTimeout(() => {
      window.location.href = `deeplink://rvshare/${target}`;
    }, 0);
  }

  shouldDisableVideoQualitySetting(): boolean {
    const paramVal = this.locationHelperService.getSearchParam("disableScreenShareVideoQuality");

    return paramVal != null && paramVal !== "false";
  }
}

angular.module('risevision.apps.services')
  .factory('rvShareAppDetection', downgradeInjectable(RvshareAppDetectionService));
