import { Component } from '@angular/core';

import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'company-banner',
  templateUrl: './company-banner.component.html',
  styleUrls: ['./company-banner.component.scss']
})
export class CompanyBannerComponent {

  constructor(public companyStateService: CompanyStateService) { }

}