import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from '../../modals/modal.component';

@Component({
  selector: 'upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss']
})
export class UpgradePlanComponent extends ModalComponent {

  currentPlan = '';
  needsPlan = '';
  planExpired = false;
  parentPlan = false;

  constructor(
    public modalRef: BsModalRef
  ) {
    super(modalRef);
  }

  close() {
    super.reject();
  }

  confirm() {
    super.resolve();
  }
}
