<form id="confirmForm">
  <div class="tw-flex tw-justify-end tw-mr-2">
    <button type="button" mat-icon-button (click)="close()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4 class="tw-my-0 tw-text-center">{{title}}</h4>
  <mat-dialog-content>
    <p class="align-left" [innerHTML]="message"></p>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="tw-gap-4">
    <div class="tw-flex-1">
      <button id="cancelButton" mat-stroked-button type="button" class="w-full" (click)="close()">
        {{cancelButton}}
      </button>
    </div>
    <div class="tw-flex-1">
      <button mat-flat-button type="button" color="{{confirmButtonClass}}" id="confirm-primary" class="w-full" (click)="confirm()">
        <span>{{confirmButton}}</span>
      </button>
    </div>
  </mat-dialog-actions>
</form>
  