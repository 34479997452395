import { Component, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { StorageUtilsService } from '../../services/storage-utils.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'storage-grid-view',
  templateUrl: './storage-grid-view.component.html',
  styleUrls: ['./storage-grid-view.component.scss']
})
export class StorageGridViewComponent {

  @Input() files: any[];
  @Input() folderPath: string = '';
  @Input() filesDetails;
  @Input() fileListVisible;
  @Input() search;
  @Output() fileClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public storageUtilsService: StorageUtilsService,
    public storageService: StorageService,
    private ngZone: NgZone
  ) { }

  triggerFileClick (file) {
    this.fileClick.emit(file);
  }

  onTooltipShown () {
    setTimeout(() => {
      this.ngZone.run(() => {});
    });
  }
}