import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';

import { routes } from './screen-sharing.routes';
import { ScreenSharingComponent } from './components/screen-sharing/screen-sharing.component';
import { ViewportComponent } from './viewport/viewport.component';
import { FormsModule } from '@angular/forms';
import { CodeInputModule } from 'angular-code-input';
import { ModeratorJoinComponent } from './components/moderator-join/moderator-join.component';
import { ModeratorRoomComponent } from './components/moderator-room/moderator-room.component';
import { ScreenSharingControlsComponent } from './components/screen-sharing-controls/screen-sharing-controls.component';

@NgModule({
  declarations: [
    ModeratorJoinComponent,
    ModeratorRoomComponent,
    ScreenSharingComponent,
    ScreenSharingControlsComponent,
    ViewportComponent
  ],
  imports: [
    UIRouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ComponentsModule,
    SharedModule,
    PopoverModule.forRoot(),
    MatSlideToggleModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatFormFieldModule,
    MatSelectModule,
    CodeInputModule
  ]
})
export class ScreenSharingModule {
}
