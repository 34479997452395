import { Component, Input, Output, EventEmitter, NgZone, ChangeDetectorRef } from '@angular/core';
import { ScrollingListService, DownloadService, HelpWidgetFactory, ProcessErrorCode } from 'src/app/ajs-upgraded-providers';
import { StorageService } from '../../services/storage.service';
import { StorageUtilsService } from '../../services/storage-utils.service';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { NewFolderModalComponent } from '../new-folder-modal/new-folder-modal.component';
import { PromiseUtilsService } from 'src/app/shared/services/promise-utils.service';

import { cloneDeep } from 'lodash';

import { StorageApiService } from 'src/app/api/services/storage-api.service';

@Component({
  selector: 'files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss']
})
export class FilesListComponent {

  @Input() folderPath: string = '';
  @Input() isListView: boolean = false;
  @Input() allowUpload: boolean = true;
  @Input() allowBatchOperations: boolean = true;
  @Input() excludedFiles: string[] = [];
  @Output() filesSelected: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() selectedItemsCount: EventEmitter<number> = new EventEmitter<number>();

  search = {
    doSearch: () => { },
    sortBy: 'name',
    reverse: false,
    onListUpdate: () => {
      this.changeDetectorRef.detectChanges();
    }
  };

  filesDetails = {
    code: 202,
    apiError: null,
    checkedCount: 0,
    folderCheckedCount: 0,
    checkedItemsCount: 0,
    bucketExists: false
  };
  uploadQueueLength = 0;
  lastClickTime = 0;
  fileListStatus = {
    code: 200
  };

  get listOperations(): any {
    const listOperations = {
      selectAll: this.selectAllCheckboxes.bind(this),
      deselectAll: this.resetSelections.bind(this)
    } as any;

    if (this.allowBatchOperations) {
      listOperations.name = 'File';
      listOperations.operations = [
        {
          name: 'Download',
          actionCall: (file: any) => {
            if (this.storageUtilsService.fileIsFolder(file)) {
              return this.promiseUtilsService.delay(100).then(() => {
                return this.downloadService.downloadFolder(file);
              });
            } else {
              return this.promiseUtilsService.delay(100).then(() => {
                return this.downloadService.downloadFile(file);
              });
            }
          },
          afterBatchAction: () => {
            return this.promiseUtilsService.delay(1000).then(() => {
              this.downloadService.cleanUp();
              return Promise.resolve();
            });
          }
        },
        {
          name: 'Copy URL',
          beforeBatchAction: () => {
            return this.storageService.showCopyUrlModal(this.files.getSelected()[0]);
          },
          hidden: () => { return this.files.getSelected().length > 1 || this.isTrashFolder; }
        },
        {
          name: 'Rename',
          beforeBatchAction: () => {
            return this.storageService.showRenameFileModal(this.files.getSelected()[0],
              (file: any, newName: string) => {
                return this._showBreakLinkWarning().then(() => {
                  const suffix = this.storageUtilsService.fileIsFolder(file) && !
                    this.storageUtilsService.fileIsFolder({
                      name: newName
                    }) ? '/' : '';
                  const renameName = newName + suffix;
                  const newObject = cloneDeep(file);

                  return this.storageApiService.rename(file.name, renameName)
                    .then(() => {
                      return this.promiseUtilsService.delay(500).then(() => {
                        newObject.name = renameName;

                        return this._refreshThumbnail(newObject);
                      });
                    })
                    .then(() => {
                      this.removeFiles([file]);
                      this.addFile(newObject);
                      this.resetSelections();
                    });
                });
              });
          },
          hidden: () => { return this.files.getSelected().length > 1 || this.isTrashFolder; }
        },
        {
          name: 'Duplicate',
          actionCall: (sourceObject) => {
            const newObject = cloneDeep(sourceObject);

            return this.storageApiService.duplicate(sourceObject.name)
              .then((resp) => {
                return this.promiseUtilsService.delay(500).then(() => {
                  newObject.name = resp.message;

                  return this._refreshThumbnail(newObject);
                });
              })
              .then(() => {
                this.addFile(newObject);
                this.resetSelections();
              });
          },
          hidden: () => { return this.isTrashFolder; }
        },
        {
          name: 'Move',
          beforeBatchAction: () => {
            return this.storageService.showFolderSelectorModal(
              '', this.files.getSelected().map(file => file.name)
            ).then((destination) => {
              return this._showBreakLinkWarning().then(() => {
                const folder = destination[0];
                return folder.name === '/' ? '' : folder.name;
              });
            });
          },
          actionCall: (file, destination) => {
            const renameTo = destination + this.storageUtilsService.fileName(file);
            return this.storageApiService.rename(file.name, renameTo);
          },
          reloadList: true,
          hidden: () => { return this.isTrashFolder; }
        },
        {
          name: 'Move To Trash',
          beforeBatchAction: () => {
            return this._showBreakLinkWarning();
          },
          actionCall: this.storageApiService.moveToTrash.bind(this.storageApiService),
          groupBy: true,
          reloadList: true,
          hidden: () => { return this.isTrashFolder; }
        },
        {
          name: 'Restore From Trash',
          actionCall: this.storageApiService.restoreFromTrash.bind(this.storageApiService),
          groupBy: true,
          reloadList: true,
          hidden: () => { return !this.isTrashFolder; }
        },
        {
          name: 'Delete Permanently',
          beforeBatchAction: () => {
            const filesSelected = this.filesDetails.checkedCount > 0;
            const foldersSelected = this.filesDetails.folderCheckedCount > 0;
            const count = this.filesDetails.checkedItemsCount;
            const singular = this.filesDetails.checkedItemsCount === 1;
            let title, message;

            if (filesSelected && foldersSelected) {
              title = 'files and folders';
              message = 'these ' + title;
            } else {
              if (foldersSelected) {
                title = 'folder' + (singular ? '' : 's');
              } else {
                title = 'file' + (singular ? '' : 's');
              }

              message = (singular ? 'this' : 'these ' + count) + ' ' + title;
            }

            return this.modalService.confirmDanger('Delete ' + title + '?',
              'Are you sure you want to delete ' + message + '?',
              'Delete Forever'
            );
          },
          actionCall: this.storageApiService.deleteFiles.bind(this.storageApiService),
          groupBy: true,
          reloadList: true,
          hidden: () => { return !this.isTrashFolder; }
        }
      ];
    }
    return listOperations;
  }

  files = this.scrollingListService(
    this.refreshFilesList.bind(this),
    this.search,
    this.listOperations);

  filterConfig = {
    placeholder: 'Search for files or folders',
    id: 'storageSelectorSearchInput'
  };

  get isTrashFolder(): boolean {
    return this.storageUtilsService.isTrashFolder(this.folderPath);
  }

  constructor(
    public currentPlanService: CurrentPlanService,
    public storageService: StorageService,
    private scrollingListService: ScrollingListService,
    private storageApiService: StorageApiService,
    private storageUtilsService: StorageUtilsService,
    private modalService: ModalService,
    private downloadService: DownloadService,
    private promiseUtilsService: PromiseUtilsService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    public helpWidgetFactory: HelpWidgetFactory,
    private processErrorCode: ProcessErrorCode
  ) { }

  private _showBreakLinkWarning() {
    return this.modalService.confirmDanger('Warning!',
      'Modifying these files or folders will break all links with existing Presentations.<br />If you are using this asset in any Presentation, the link for each must be manually updated in the Editor.')
      .catch(() => {
        return Promise.reject('cancel');
      });
  }

  private _refreshThumbnail(file) {
    if (!this.storageUtilsService.fileIsFolder(file)) {
      return this.storageApiService.getFiles({
        folderPath: this.folderPath,
        file: file.name
      })
        .then((resp) => {
          return resp && resp.files && resp.files[0] ? resp.files[0] : file;
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      return Promise.resolve({ ...file });
    }
  }

  refreshFilesList(): Promise<any> {
    const params = {
      folderPath: undefined
    };
    if (this.folderPath) {
      params.folderPath = this.folderPath;
    }

    this.filesDetails.code = 202;
    this.filesDetails.apiError = null;

    return this.storageApiService.getFiles(params).then((resp) => {
      const parentFolder = this.folderPath;
      const result = {
        items: []
      };

      resp.files = resp.files || [];

      result.items = resp.files.filter((f) => {
        let include = true;

        if (this.excludedFiles.indexOf(f.name) !== -1) {
          include = false;
        }

        if (f.name === parentFolder) {
          include = false;
        }

        if (this.storageService.isFolderFilter() &&
          !this.storageUtilsService.fileIsFolder(f)) {
          include = false;
        }

        return include;
      });

      this.filesDetails.bucketExists = resp.bucketExists;
      this.filesDetails.code = resp.code;

      if (!this.folderPath || !parentFolder || parentFolder === '/') {
        // [AD] There may be a reason why the trash folder is added in
        // the second position; from legacy Storage
        result.items.splice(1, 0, {
          name: StorageUtilsService.TRASH_FOLDER,
          size: '',
          updated: null
        });

        if (this.storageService.isFolderFilter()) {
          result.items.splice(1, 0, {
            name: '/',
            size: '',
            updated: null
          });
        }
      }

      return result;
    }).catch((error) => {
      this.filesDetails.code = error.error.code;
      this.filesDetails.apiError = this.processErrorCode(error);
    });
  }

  addFile(newFile) {
    const currentFolder = this.folderPath ? this.folderPath : '';
    const idx = newFile.name.indexOf('/', currentFolder.length);
    // Handles the case where a file inside a folder was added (since files are not visible, only adds the folder)
    const fileName = idx >= 0 ? newFile.name.substring(0, idx + 1) : newFile.name;
    let existingFileNameIndex = -1;

    for (let i = 0, j = this.files.items.list.length; i < j; i += 1) {
      if (this.files.items.list[i].name === fileName) {
        existingFileNameIndex = i;
        break;
      }
    }

    if (idx >= 0) {
      if (existingFileNameIndex === -1) {
        this.files.items.list.push({
          name: fileName,
          kind: 'folder'
        });
      }
    } else if (existingFileNameIndex !== -1) {
      this.files.items.list.splice(existingFileNameIndex, 1, newFile);
    } else {
      this.files.items.list.push(newFile);
    }

    // Needed because file upload does not refresh the list with a server call
    this.filesDetails.bucketExists = true;
  }

  addFolder() {
    if (!this.currentPlanService.isPlanActive()) {
      return this.currentPlanService.showUnlockThisFeatureModal();
    }

    this.modalService.showMediumModal(NewFolderModalComponent, {
      initialState: {
        folderPath: this.folderPath,
        existingFiles: this.files.items.list,
        returnFocusId: 'newFolderButton'
      }
    }).then((folder) => {
      console.debug('Created folder ' + folder);
      this.files.doSearch();
    }).catch(() => { });
  }

  removeFiles(files) {
    const oldFiles = this.files.items.list;

    for (let i = oldFiles.length - 1; i >= 0; i--) {
      if (files.indexOf(oldFiles[i]) >= 0) {
        oldFiles.splice(i, 1);
      }
    }
  }

  resetSelections() {
    this.files.items.list.forEach((val) => {
      val.selected = false;
    });

    this.filesDetails.checkedCount = 0;
    this.filesDetails.folderCheckedCount = 0;
    this.filesDetails.checkedItemsCount = 0;
    this.selectedItemsCount.emit(0);
  }

  selectAllCheckboxes() {
    const filteredFiles = this.storageUtilsService.filterFiles(this.files.items.list, this.search, this.storageService.storageFull);

    this.filesDetails.checkedCount = 0;
    this.filesDetails.folderCheckedCount = 0;
    this.filesDetails.checkedItemsCount = 0;

    for (let i = 0; i < this.files.items.list.length; ++i) {
      const file = this.files.items.list[i];
      if (this.storageUtilsService.fileIsTrash(file) ||
        (this.storageUtilsService.fileIsFolder(file) &&
          !this.storageService.isFolderSelector())) {
        continue;
      }

      file.selected = this.files.search.selectAll && filteredFiles.indexOf(file) >= 0;

      if (file.name.substr(-1) !== '/') {
        this.filesDetails.checkedCount += file.selected ? 1 : 0;
      } else {
        this.filesDetails.folderCheckedCount += file.selected ? 1 : 0;
      }

      this.filesDetails.checkedItemsCount += file.selected ? 1 : 0;
    }
    this.selectedItemsCount.emit(this.filesDetails.checkedItemsCount);
  }

  private _fileCheckToggled(file) {
    // ng-click is processed before btn-checkbox updates the model
    const checkValue = !file.selected;

    if (this.files.search.selectAll && !checkValue) {
      this.files.search.selectAll = false;
    }
    file.selected = checkValue;

    if (checkValue) {
      let allSelected = true;
      for (var i = 0; i < this.files.items.list.length; ++i) {
        const f = this.files.items.list[i];

        if (this.storageUtilsService.fileIsTrash(f) ||
          (this.storageUtilsService.fileIsFolder(f) &&
            !this.storageService.isFolderSelector())) {
          continue;
        }
        if (!f.selected) {
          allSelected = false;
          break;
        }
      }
      this.files.search.selectAll = allSelected;
    }

    if (file.name.substr(-1) !== '/') {
      this.filesDetails.checkedCount += checkValue ? 1 : -1;
    } else {
      this.filesDetails.folderCheckedCount += checkValue ? 1 : -1;
    }

    this.filesDetails.checkedItemsCount += checkValue ? 1 : -1;
    this.selectedItemsCount.emit(this.filesDetails.checkedItemsCount);
  }

  private _singleFileToggled(file) {
    // ng-click is processed before btn-checkbox updates the model
    const checkValue = !file.selected;

    if (checkValue) {
      this.resetSelections();
    }

    this._fileCheckToggled(file);
  }

  sendFiles() {
    this.filesSelected.emit(this.files.getSelected());
  }

  onFileSelect(file) {
    if (this.storageService.canSelect(file)) {
      if (!this.storageService.isMultipleSelector()) {
        this._singleFileToggled(file);
      } else {
        this._fileCheckToggled(file);
      }
      this.ngZone.run(() => { });
    }
  }

  changeFolder(folder) {
    if (this.storageUtilsService.fileIsFolder(folder)) {
      this.resetSelections();

      this.folderPath = folder.name;

      this.files.doSearch();
    }
  }

  fileClick(file) {
    if (this.storageUtilsService.fileIsFolder(file) && file.name !== '/') {
      const dblClickDelay = 300;
      const currentTime = (new Date()).getTime();

      if (currentTime - this.lastClickTime < dblClickDelay) {
        this.lastClickTime = 0;

        this.changeFolder(file);
      } else {
        this.lastClickTime = currentTime;

        // Use a small delay to avoid selecting a folder when the intention was navigating into it
        setTimeout(() => {
          const currentTime = (new Date()).getTime();

          if (this.lastClickTime !== 0 && currentTime - this.lastClickTime >= dblClickDelay) {
            this.onFileSelect(file);
          }
        }, dblClickDelay);
      }
    } else {
      if (file.isThrottled) {
        file.showThrottledCallout = !file.showThrottledCallout;
        // calloutClosingService.add(file);
        return;
      }

      this.onFileSelect(file);
    }
  }

  isFileListVisible() {
    return !(this.files.loadingItems || this.filesDetails.code === 202 || this.isEmptyState());
  }

  isEmptyState() {
    return !this.folderPath &&
      this.uploadQueueLength === 0 &&
      !this.filesDetails.bucketExists;
  }
}