import { Injectable } from '@angular/core';

import { ExceptionHandler, CoreAPILoader } from 'src/app/ajs-upgraded-providers';

@Injectable({
  providedIn: 'root'
})
export class UserauthApiService {

  constructor(
    private coreAPILoader: CoreAPILoader,
    private $exceptionHandler: ExceptionHandler) { }

  add(username, password, hash) {
    var obj = {
      data: {
        username: username,
        password: password,
        joinHash: hash ? hash : ''
      }
    };
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.userauth.add(obj);
      })
      .then((resp) => {
        console.debug('added user credentials', resp);

        return resp;
      })
      .catch(function (e) {
        console.error('Failed to add credentials.', e);

        throw e;
      });
  }

  updatePassword(username, oldPassword, newPassword) {
    var obj = {
      data: {
        username: username,
        oldPassword: oldPassword,
        newPassword: newPassword
      }
    };

    return this.coreAPILoader().then((coreApi) => {
        return coreApi.userauth.updatePassword(obj);
      })
      .then((resp) => {
        console.debug('update user credentials resp', resp);

        return resp;
      })
      .catch((e) => {
        console.error('Failed to update credentials.', e);

        throw e;
      });
  }

  login(username, password, userConfirmedToken?) {
    var obj = {
      data: {
        username: username,
        password: password,
        userConfirmedToken: userConfirmedToken
      }
    };

    return this.coreAPILoader().then((coreApi) => {
        return coreApi.userauth.login(obj);
      })
      .then((resp) => {
        console.debug('login successful', resp);

        return resp;
      })
      .catch((e) => {
        console.error('Failed to login user.', e);

        throw e;
      });
  }

  refreshToken(username, token) {
    var obj = {
      data: {
        token: token
      }
    };

    return this.coreAPILoader().then((coreApi) => {
        return coreApi.userauth.refreshToken(obj);
      })
      .then((resp) => {
        console.debug('token refresh successful', resp);

        return resp;
      })
      .catch((e) => {
        console.error('Failed to refresh token.', e);

        throw e;
      });
  }

  requestConfirmationEmail(username) {
    var obj = {
      data: {
        username: username
      }
    };
    return this.coreAPILoader().then(coreApi => {
        return coreApi.userauth.requestConfirmationEmail(obj);
      })
      .then(resp => {
        console.debug('Request confirmation email successful', resp);
        return resp;
      })
      .then(null, e => {
        this.$exceptionHandler(e, 'Failed to request confirmation email.', true);
        throw e;
      });
  }

  requestPasswordReset(username) {
    var obj = {
      data: {
        username: username
      }
    };

    return this.coreAPILoader().then((coreApi) => {
        return coreApi.userauth.requestPasswordReset(obj);
      })
      .then((resp) => {
        console.debug('Request password reset successful', resp);

        return resp;
      })
      .catch((e) => {
        console.error('Failed to request password reset.', e);

        throw e;
      });
  }

  resetPassword(username, passwordResetToken, newPassword) {
    var obj = {
      data: {
        username: username,
        passwordResetToken: passwordResetToken,
        newPassword: newPassword
      }
    };

    return this.coreAPILoader().then((coreApi) => {
        return coreApi.userauth.resetPassword(obj);
      })
      .then((resp) => {
        console.debug('Reset password successful', resp);

        return resp;
      })
      .catch((e) => {
        console.error('Failed to reset password.', e);

        throw e;
      });
  }

  canProceedWithSSO(username, companyId?) {
    var obj: any = {
      id: username
    };
    if (companyId) {
      obj.companyId = companyId;
    }
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.userauth.canProceedWithSSO(obj);
      })
      .then((resp) => {
        console.debug('Can proceed with SSO', resp);
        return resp;
      })
      .catch((e) => {
        console.error('Failed to check can proceed with SSO.', e);
        throw e;
      });
  }

}