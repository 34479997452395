import { Component, Input } from '@angular/core';

@Component({
  selector: 'last-modified',
  templateUrl: './last-modified.component.html',
  styleUrls: ['./last-modified.component.scss']
})
export class LastModifiedComponent {

  @Input() changeDate: Date;
  @Input() changedBy: string;

}