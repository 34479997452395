import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'auth-common',
  templateUrl: './auth-common.component.html',
  styleUrl: './auth-common.component.scss'
})
export class AuthCommonComponent implements OnInit {

  private readonly CYCLE_INTERVAL = 7000;
  private readonly FADE_DURATION = 500;

  backgroundImage = '';
  previewImages = [];
  image = 1;
  cycleImagesTimeout: any;

  @ViewChild('previewElement') previewElement: ElementRef<HTMLImageElement>;

  constructor() {
    this.previewImages[0] = new Image();
    this.previewImages[0].src = '/images/sign-up/preview1.jpg';
    this.previewImages[0].alt = 'Slide with New York weather forecast';
    this.previewImages[1] = new Image();
    this.previewImages[1].src = '/images/sign-up/preview2.jpg';
    this.previewImages[1].alt = 'Slide with employee spotlight';
    this.previewImages[2] = new Image();
    this.previewImages[2].src = '/images/sign-up/preview3.jpg';
    this.previewImages[2].alt = 'Slide with motivational text';
  }

  ngOnInit(): void {
    var rand = Math.random();
    this.backgroundImage = rand < 0.25 ? '/images/sign-up/room1.jpg'
      : rand < 0.5 ? '/images/sign-up/room2.jpg'
      : rand < 0.75 ? '/images/sign-up/room3.jpg'
      : '/images/sign-up/room4.jpg';

    this.cycleImagesTimeout = setTimeout(this.cycleImages.bind(this), this.CYCLE_INTERVAL);
  }

  ngOnDestroy(): void {
    clearTimeout(this.cycleImagesTimeout);
  }

  cycleImages() {
    if (!document.images || !this.previewElement?.nativeElement) {
      return;
    }

    const element = this.previewElement.nativeElement;
    element.className = 'fade';

    setTimeout(() => {
      element.src = this.previewImages[this.image].src;
      element.alt = this.previewImages[this.image].alt;
      this.image += 1;
      element.className = '';
      if (this.image > 2) {
        this.image = 0;
      }
    }, this.FADE_DURATION);

    this.cycleImagesTimeout = setTimeout(this.cycleImages.bind(this), this.CYCLE_INTERVAL);
  }

}
