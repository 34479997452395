<form id="messageForm">
  <div class="tw-flex tw-justify-end tw-mt-2 tw-mr-2">
    <button type="button" mat-icon-button (click)="close()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <h4 class="tw-mt-0 tw-text-center">{{title}}</h4>
  <mat-dialog-content>
    <p class="align-left" [innerHTML]="message"></p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button
      id="okayButton"
      color="primary"
      type="button"
      class="tw-w-full"
      (click)="close()">
      Okay, I Got It
    </button>
  </mat-dialog-actions>
</form>
