import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent {

  title: string;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<MessageBoxComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: MessageBoxData) {
      this.title = data.title;
      this.message = data.message;
    }

  close() {
    this.dialogRef.close();
  }

}

export interface MessageBoxData {
  title: string;
  message: string;
}
