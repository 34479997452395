import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from 'src/environments/environment';

export type ApiSearchLegacy = {
  query?: string;
  filter?: string;
  count?: number | string;
  sortBy?: string;
  reverse?: boolean
}

export type ApiSearch = {
  query?: string;
  filter?: string;
  search?: string;
  includeSubcompanies?: boolean,
  count?: number | string;
  sortBy?: string;
  reverse?: boolean
}

export function getError(error?: any): any {
  if (error) {
    if (error.result) {
      if (error.result.error) {
        return error.result.error;
      } else {
        return error.result;
      }
    } else if (error.error) {
      return error.error;
    } else {
      return error;
    }
  } else {
    return {};
  }
}

export function humanReadableError(resp?: any): string {
  if (!resp) {
    return 'Unknown Error';
  }

  const error = getError(resp);
  const message = error.message || error;

  if (typeof message === 'string') {
    return message;
  } else {
    return JSON.stringify(message);
  }
}

export function isAuthorizedEndpoint(url: string): boolean {
  return url.startsWith(environment.SPRING_ENDPOINT_URL) ||
    url.startsWith(environment.SPRING_STORAGE_ENDPOINT_URL) ||
    url.startsWith(environment.SPRING_STORE_ENDPOINT_URL) ||
    url.startsWith(environment.CORE_URL);
}

@Injectable({
  providedIn: 'root'
})
export class ApiUtilsService {

  constructor() { }

  getError(error?: any): any {
    return getError(error);
  }

  humanReadableError(resp?: any): string {
    return humanReadableError(resp);
  }

  isAuthorizedEndpoint(url: string): boolean {
    return isAuthorizedEndpoint(url);
  }
}

angular.module('risevision.apps.services')
  .factory('apiUtils', downgradeInjectable(ApiUtilsService));
