import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UIRouterModule } from '@uirouter/angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { SharedModule } from '../shared/shared.module';

import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { BackgroundImageComponent } from './background-image/background-image.component';
import { BetaBadgeComponent } from './beta-badge/beta-badge.component';
import { CalendarMonthYearHeaderComponent } from './calendar-month-year-header/calendar-month-year-header.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { DistributionListComponent } from './distribution/distribution-list/distribution-list.component';
import { DistributionModalComponent } from './distribution/distribution-modal/distribution-modal.component';
import { DistributionSelectorComponent } from './distribution/distribution-selector/distribution-selector.component';
import { EmailsFieldComponent } from './emails-field/emails-field.component';
import { FocusMeDirective } from './focus-me/focus-me.directive';
import { LastModifiedComponent } from './last-modified/last-modified/last-modified.component';
import { LastRevisedComponent } from './last-modified/last-revised/last-revised.component';
import { LoadingService } from './loading/loading.service';
import { RvSpinnerDirective } from './loading/rv-spinner.directive';
import { TrackerService } from './logging/tracker.service';
import { BulkEditModalComponent } from './modals/bulk-edit-modal/bulk-edit-modal.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { MessageBoxComponent } from './modals/message-box/message-box.component';
import { ModalService } from './modals/modal.service';
import { NumberInputComponent } from './number-input/number-input.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { CurrentPlanService } from './plans/current-plan.service';
import { FeaturesService } from './plans/features.service';
import { UnlockFeatureComponent } from './plans/unlock-feature/unlock-feature.component';
import { UpgradeFeatureModalComponent } from './plans/upgrade-feature-modal/upgrade-feature-modal.component';
import { UpgradeNoticeComponent } from './plans/upgrade-notice/upgrade-notice.component';
import { UpgradePlanComponent } from './plans/upgrade-plan/upgrade-plan.component';
import { BatchOperationsComponent } from './scrolling-list/batch-operations/batch-operations.component';
import { BulkDeleteConfirmationComponent } from './scrolling-list/bulk-delete-confirmation/bulk-delete-confirmation.component';
import { CompanyIdModalComponent } from './scrolling-list/company-id-modal/company-id-modal.component';
import { ScrollingListDirective } from './scrolling-list/scrolling-list.directive';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { StreamlineIconComponent } from './streamline-icon/streamline-icon.component';
import { StretchyInputComponent } from './stretchy-input/stretchy-input.component';
import { SuggestGeneralDeliveryComponent } from './suggest-general-delivery/suggest-general-delivery.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { PopupTimePickerComponent } from './time-date/popup-time-picker/popup-time-picker.component';
import { DateEndAfterStartDirective } from './timeline/date-end-after-start.directive';
import { MonthDropdownComponent } from './timeline/month-dropdown/month-dropdown.component';
import { PickTimeRangeComponent } from './timeline/pick-time-range/pick-time-range.component';
import { TimelineBasicModalComponent } from './timeline/timeline-basic-modal/timeline-basic-modal.component';
import { TimelineBasicTextboxComponent } from './timeline/timeline-basic-textbox/timeline-basic-textbox.component';
import { TimelineDescriptionService } from './timeline/timeline-description.service';
import { TimelineModalComponent } from './timeline/timeline-modal/timeline-modal.component';
import { TimelineTextboxComponent } from './timeline/timeline-textbox/timeline-textbox.component';
import { TimelineService } from './timeline/timeline.service';
import { WeekDropdownComponent } from './timeline/week-dropdown/week-dropdown.component';
import { WeekdayCheckboxesComponent } from './timeline/weekday-checkboxes/weekday-checkboxes.component';
import { WeekdayDropdownComponent } from './timeline/weekday-dropdown/weekday-dropdown.component';
import { UrlFieldComponent } from './url-field/url-field.component';
import { UrlFileTypeValidatorDirective } from './url-field/validators/file-type-validator.directive';
import { HttpValidatorDirective } from './url-field/validators/http-validator.directive';
import { UrlPatternValidatorDirective } from './url-field/validators/url-pattern-validator.directive';
import { ContentTitleComponent } from './content-title/content-title.component';

@NgModule({
  declarations: [
    MessageBoxComponent,
    ColorPickerComponent,
    ConfirmModalComponent,
    LastModifiedComponent,
    LastRevisedComponent,
    StreamlineIconComponent,
    StretchyInputComponent,
    RvSpinnerDirective,
    PopupTimePickerComponent,
    SearchFilterComponent,
    ScrollingListDirective,
    BatchOperationsComponent,
    FocusMeDirective,
    TimelineTextboxComponent,
    SuggestGeneralDeliveryComponent,
    EmailsFieldComponent,
    PasswordInputComponent,
    SvgIconComponent,
    BackgroundImageComponent,
    DistributionSelectorComponent,
    BetaBadgeComponent,
    CompanyIdModalComponent,
    BulkDeleteConfirmationComponent,
    DistributionListComponent,
    DistributionModalComponent,
    TimelineModalComponent,
    MonthDropdownComponent,
    WeekdayDropdownComponent,
    WeekDropdownComponent,
    DateEndAfterStartDirective,
    UnlockFeatureComponent,
    UpgradePlanComponent,
    TimelineBasicModalComponent,
    PickTimeRangeComponent,
    WeekdayCheckboxesComponent,
    TimelineBasicTextboxComponent,
    CalendarMonthYearHeaderComponent,
    BulkEditModalComponent,
    NumberInputComponent,
    UpgradeNoticeComponent,
    UpgradeFeatureModalComponent,
    ContentTitleComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressbarModule,
    TooltipModule,
    BsDropdownModule.forRoot(),
    TimepickerModule.forRoot(),
    PopoverModule.forRoot(),
    SharedModule,
    UIRouterModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatChipsModule,
    MatDatepickerModule,
    MatIconModule,
    MatNativeDateModule,
    UrlFieldComponent,
    UrlPatternValidatorDirective,
    HttpValidatorDirective,
    UrlFileTypeValidatorDirective,
    MatMenuModule,
    MatDialogModule
  ],
  exports: [
    ColorPickerComponent,
    LastModifiedComponent,
    LastRevisedComponent,
    StreamlineIconComponent,
    StretchyInputComponent,
    RvSpinnerDirective,
    PopupTimePickerComponent,
    SearchFilterComponent,
    ScrollingListDirective,
    BatchOperationsComponent,
    FocusMeDirective,
    TimelineTextboxComponent,
    SuggestGeneralDeliveryComponent,
    EmailsFieldComponent,
    PasswordInputComponent,
    SvgIconComponent,
    BackgroundImageComponent,
    DistributionSelectorComponent,
    BetaBadgeComponent,
    DistributionSelectorComponent,
    TimelineBasicTextboxComponent,
    NumberInputComponent,
    UpgradeNoticeComponent,
    ContentTitleComponent
  ]
})
export class ComponentsModule {
  //workaround for including downgraded components into build files
  //https://github.com/angular/angular/issues/35314#issuecomment-584821399
  static providers = [ ModalService, LoadingService, TrackerService, TimelineService, TimelineDescriptionService, CurrentPlanService, FeaturesService ];
}
