import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'list-grid-toggle',
  templateUrl: './list-grid-toggle.component.html',
  styleUrls: ['./list-grid-toggle.component.scss']
})
export class ListGridToggleComponent {

  @Input() isListView: boolean;
  @Output() isListViewChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleListView () {
    this.isListViewChange.emit(!this.isListView);
  }
}