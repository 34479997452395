<div [id]="id" class="madero-style alert alert-{{type}} {{cssClass}}" role="alert" aria-live="polite">
  @if (title) {
    <i aria-hidden="true" class="fa icon-left {{iconClass[type]}}"></i>
    <span [ngClass]="{ 'title-bold': boldTitle }">{{title}}</span>
  }
  @if (message) {
    <p class="mt-2">{{message}}</p>
  }
  <div class="mt-2 content-wrapper">
    <ng-content></ng-content>
  </div>
</div>