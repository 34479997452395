import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnInit } from '@angular/core';

import { UploadManagerConfig, UploadManagerService } from '../../services/upload-manager.service';

@Component({
  selector: 'upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  @Input() folderPath: string = '';
  @Output() addFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() queueLengthChange: EventEmitter<number> = new EventEmitter<number>();

  uploader: any;
  isCollapsed: boolean = false;

  constructor (
    private uploadManagerService: UploadManagerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.initFileUploader();
  }

  initFileUploader() {
    let uploadManagerConfig: UploadManagerConfig = {
      validType: '',
      addFile: (file) => {
        this.addFile.emit(file);
      },
      onUploadStatus: () => {
        this.queueLengthChange.emit(this.uploader.queue.length);
      },
      detectChanges: () => {
        this.changeDetectorRef.detectChanges();
      },
      getFolderPath: () => {
        return this.folderPath;
      }
    };

    this.uploader = this.uploadManagerService.getFileUploader(uploadManagerConfig);
  }

  someEncoding () {
    return this.uploader.someEncoding();
  }

  activeUploadCount () {
    return this.uploader.queue.filter((file) => {
      return !file.isUploaded || file.isError;
    }).length;
  }

  getErrorCount () {
    return this.uploader.getErrorCount();
  }

  getNotErrorCount () {
    return this.uploader.getNotErrorCount();
  }

  storageFileSelect (event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    this.uploader.uploadSelectedFiles(element.files)
      .finally(() => {
        element.value = '';
      });
  }

}